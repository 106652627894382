import { Alert, Autocomplete, Box, Button, Snackbar, TextField, Typography } from '@mui/material'
import React from 'react'

import backendDevelopment1 from '../assets/lights/backendDevelopment1.png'
import backendDevelopment2 from '../assets/lights/backendDevelopment2.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import logo from "../assets/essentials/logo.png"
import { Link } from 'react-router-dom';
import axios from 'axios';

const services = [
  "User Interface Design",
  "Backend Development",
  "Frontend Development",
  "Frontend + Backend Development",
  "Dev Ops",
  "All in one",
  "Enterprise Solution",
  "App Development"
]
const budgets = [
  "1k - 5k",
  "10k - 25k",
  "25k - 50k",
  "50k - 100k",
  "100k+"
]

function Contact({language}) {
  var defaultAlert = {
    open: false,
    type: "info",
    message: "",
  }
  const [alert, setAlert] = React.useState(defaultAlert);

  const [fullName, setFullName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [service, setService] = React.useState("");
  const [budget, setBudget] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [fullNameError, setFullNameError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [serviceError, setServiceError] = React.useState(false);
  const [budgetError, setBudgetError] = React.useState(false);
  const [messageError, setMessageError] = React.useState(false);

  const onSend = async () => {
    if(fullName !== "") {setFullNameError(false)};
    if(email !== "") {setEmailError(false)};
    if(service !== "") {setServiceError(false)};
    if(budget !== "") {setBudgetError(false)};
    if(message !== "") {setMessageError(false)};
    if(fullName === "" || email === "" || service === "" || budget === "" || message === "") {
      if(fullName === "") {setFullNameError(true)};
      if(email === "") {setEmailError(true)};
      if(service === "") {setServiceError(true)};
      if(budget === "") {setBudgetError(true)};
      if(message === "") {setMessageError(true)};
      return;
    }
    var form = {
      fullName: fullName,
      email: email,
      service: service,
      budget: budget,
      message: message,
      captcha: document.querySelector("#g-recaptcha-response").value
    };

    const rawResponse = await fetch('/api/form', {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(form)
    });
    const parsedResponse = await rawResponse.json();

    console.log(parsedResponse);


    setFullName("");
    setFullNameError(false);
    setEmail("");
    setEmailError(false);
    setService("");
    setServiceError(false);
    setBudget("");
    setBudgetError(false);
    setMessage("");
    setMessageError(false);
    setAlert({open: true, type: parsedResponse.type, message: parsedResponse.message});
  }

  return (
    <Box>
        <Box pt={2} className="animate__animated animate__slideInDown" display="flex" justifyContent="center" alignItems="center">
            <Link to="/">
              <Typography sx={{cursor: "pointer"}} component="img" src={logo}></Typography>
            </Link>
        </Box>

        <Typography className='light' component="img" src={backendDevelopment1} sx={{right: 0, width: {xs: "100%", lg: "30%"}, top: 10}}></Typography>
        <Typography className='light' component="img" src={backendDevelopment2} sx={{left: 0, width: {xs: "90%", lg: "24%"}, top: {xs: -80, lg: -150}}}></Typography>

        <Box className="animate__animated animate__slideInUp" mt={5} display="flex" justifyContent="center" alignItems="center">
          <Box sx={{width: {xs: "97%", lg: "55%"}}}>
            <Box textAlign="center">
              <Typography component="h2" variant="h3" fontWeight="bold">{language.title}</Typography>
              <Typography component="h6" variant="h6" fontWeight="bold" color="rgb(255,255,255,0.8)">{language.slogan}</Typography>
            </Box>

            <Box mt={4} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
              <Box sx={{width: {xs: "100%", lg: "49%"}}} mb={2}>
                <TextField fullWidth variant='outlined' label={language.labels.fullName} value={fullName} onChange={e => setFullName(e.target.value)} error={fullNameError}></TextField>
              </Box>
              <Box sx={{width: {xs: "100%", lg: "49%"}}} mb={2}>
                <TextField fullWidth variant='outlined' label={language.labels.eMail} value={email} onChange={e => setEmail(e.target.value)} error={emailError}></TextField>
              </Box>
              <Box sx={{width: {xs: "100%", lg: "49%"}}} mb={2}>
                <Autocomplete width="100%" onChange={(event, value) => setService(value)} value={service} options={services} renderInput={(params) => <TextField {...params} label={language.labels.service} error={serviceError} onChange={(event, value) => setService(value)} value={service}/>}/>
              </Box>
              <Box sx={{width: {xs: "100%", lg: "49%"}}} mb={2}>
                <Autocomplete width="100%" onChange={(event, value) => setBudget(value)} value={budget} options={budgets} renderInput={(params) => <TextField {...params} label={language.labels.budget} error={budgetError} onChange={(event, value) => setBudget(value)} value={budget}/>}/>
              </Box>
              <Box width="100%" mb={3}>
                <TextField fullWidth label={language.labels.message} multiline rows={12} onChange={(e) => setMessage(e.target.value)} value={message} error={messageError}/>
              </Box>

              <Box width="100%" textAlign="center">
                <div style={{display: "inline-block"}} class="g-recaptcha" data-sitekey="6LdGLyAjAAAAAN7WfFEU_0OXHmB2Ra9HQc5Xrpze"></div>
              </Box>

              <Box width="100%" display="flex" justifyContent="center" mt={2} mb={2}>
                <Button variant="contained" endIcon={<ArrowForwardIcon />} onClick={e => onSend()}>{language.sendButtonLabel}</Button>
              </Box>
            </Box>
          </Box>
        </Box>

        <Snackbar open={alert.open} autoHideDuration={10000} onClose={e => setAlert(defaultAlert)}>
          <Alert onClose={e => setAlert(defaultAlert)} severity={alert.type} sx={{ width: '100%' }}>
            {alert.message}
          </Alert>
        </Snackbar>
    </Box>
  )
}

export default Contact
import { Box, Typography } from '@mui/material'
import theme from "../../theme"
import React from 'react'
import logo from "../../assets/essentials/logo.png"

import Twitter from "../../assets/svg/footer/Twitter"
import Facebook from "../../assets/svg/footer/Facebook"
import Instagram from "../../assets/svg/footer/Instagram"

const icons = [
]

function Footer({language}) {
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="space-between" bgcolor="#141619" pt={15} pb={10} className="padding">
        <Box sx={{width: {xs: "98%", lg: "30%"}}} mb={4}>
            <Box display="flex" mb={3}>
                <Typography component="img" src={logo}></Typography>
            </Box>
            <Typography component="p" variant="body1" color={theme.palette.informationText.main}>{language.slogan}</Typography>
            <Box width="50%" display="flex" justifyContent="flex-start" mt={2}>
                {icons.map((icon) => {
                    return (
                        <a href={icon.link} target="_blank" rel="noreferrer">
                            <Box display="flex" justifyContent="center" alignItems="center" width="3rem" height="3rem" borderRadius={5} mr={1} sx={{backgroundColor: "rgb(255, 255, 255, 0.1)", cursor: "pointer", ":hover": {backgroundColor: "rgb(255, 255, 255, 0.01)", transition: "500ms"}}}>
                                {icon.icon}
                            </Box>
                        </a>
                    )
                })}
            </Box>
        </Box>

        <Box sx={{width: {xs: "98%", lg: "60%"}}} display="flex" flexWrap="wrap" justifyContent="right" alignItems="flex-start">
            {language.links.map((linkGroup) => {
                return (
                    <Box sx={{width: {xs: "47%", lg: "22%"}}} mb={3}>
                        <Typography component="h6" variant="h6" fontWeight="bold" mb={2}>{linkGroup.title}</Typography>
                        <Box>
                            {linkGroup.subLinks.map((subLink) => {
                                return (
                                    <a href={subLink.link} style={{textDecoration: "none"}}>
                                        <Typography component="p" variant="body1" color={theme.palette.informationText.main} sx={{cursor: "pointer", ":hover": {color: "#292929", transition: "250ms"}}}>{subLink.title}</Typography>                                    
                                    </a>
                                )
                            })}
                        </Box>
                    </Box>
                )
            })}
        </Box>
    </Box>
  )
}

export default Footer

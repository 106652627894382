import { Box, Button, Typography } from '@mui/material'
import logo from "../../assets/essentials/logo.png"
import React from 'react'
import { Link } from 'react-router-dom'

const tabs = [
    {title: "Home", link: "#home"},
    {title: "How it works", link: "#from-start-to-finish"},
    {title: "Project Planning", link: "#project-planning"},
    {title: "UI Design", link: "#ui-design"},
    {title: "Backend", link: "#backend-development"},
    {title: "Frontend", link: "#frontend-development"},
    {title: "DevOps", link: "#dev-ops"},
]

function NavBar() {
  React.useEffect(() => {
    document.addEventListener("scroll", () => {
        var navbar = document.getElementById("navbar");
        navbar.classList.add("scrolled")
    });
  }, []);
  setInterval(() => {
    if(window.scrollY === 0) {
        var navbar = document.getElementById("navbar");
        navbar.classList.remove("scrolled");
    }
  }, 1)
  return (
    <Box className="animate__animated animate__slideInDown" id="navbar" zIndex={100} sx={{display: {xs: "none", lg: "flex"}}} display="flex" width="100%" position="fixed" flexWrap="wrap" justifyContent="space-between" alignItems="center" pt={2} pb={2} pl={7} pr={7}>

        <Box zIndex={10} sx={{flex: {xs: 1, lg: 0.5}, justifyContent: "center", textAlign: {xs: "center", lg: "left"}}}>
            <Typography widht="5%" component="img" src={logo}></Typography>
        </Box>

        <Box zIndex={100} sx={{display: {xs: "none", lg: "flex"}}} flex={1.5} justifyContent="space-between">
            {tabs.map((tab) => (
                <a href={tab.link} style={{textDecoration: "none"}}>
                    <Typography key={tabs.indexOf(tab)} sx={{cursor: "pointer"}} component="p" variant="body1" color="#fff">{tab.title}</Typography>
                </a>
            ))}
        </Box>

        <Box sx={{display: {xs: "none", lg: "flex"}}} justifyContent="center" flex={0.5}>
            <Link to="/contact" style={{textDecoration: "none"}}>
                <Button sx={{borderRadius: "0.5rem"}} variant="contained">Get Started</Button>
            </Link>
        </Box>

    </Box>
  )
}

export default NavBar
import { ThemeProvider } from '@emotion/react';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Landing from "./pages/Landing"
import Contact from './pages/Contact';
import theme from "./theme"
import langs from './languages';

function App() {
  const language = langs["en"];
  /*
  const [language, setLanguage] = React.useState(langs["en"]);

  
  React.useEffect(() => {
    if(navigator.language.match(/de/g) !== null) {
      setLanguage(langs["de"]);
    }
  }, []);
  */
  

  return (
    <Router>
      <div style={{backgroundColor: "#101010", color: "#fff", width: "100%"}}>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path='/' element={<Landing language={language}/>} />
            <Route path='/contact' element={<Contact language={language.contact}/>} />
          </Routes>
        </ThemeProvider>
      </div>
    </Router>
  );
}

export default App;
